export default {
  path: '/setting',
  component: () => import('@/views/setting/index.vue'),
  children: [
    {
      path: '',
      name: 'Setting'
    },
    {
      path: 'dev',
      name: 'DevSetting',
      component: () => import('@/views/setting/page/Dev.vue'),
      meta: {
        middleware: [
          'auth',
          'developer'
        ]
      }
    },
    {
      path: 'store-information',
      name: 'SettingStoreInformation',
      component: () => import('@/views/setting/page/StoreInformation.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'store-setting',
      name: 'StoreSetting',
      component: () => import('@/views/setting/page/StoreSetting.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'campaign-setting',
      name: 'CampaignSetting',
      component: () => import('@/views/setting/page/CampaignSetting.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'available-payment-channel',
      name: 'AvailablePaymentChannelSetting',
      component: () => import('@/views/setting/page/AvailablePaymentChannel.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer'
        ]
      }
    },
    {
      path: 'delivery',
      name: 'DeliverySetting',
      component: () => import('@/views/setting/page/Delivery.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'accounting_manager',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'warehouse',
      name: 'WarehouseSetting',
      component: () => import('@/views/setting/page/Warehouse.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'accounting_manager',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'order-fulfillment-setting',
      name: 'OrderFulfillmentSetting',
      component: () => import('@/views/setting/page/OrderFulfillmentSetting.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'user-branch',
      name: 'UserBranchSetting',
      component: () => import('@/views/setting/page/UserBranch.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'developer',
          'accounting_manager',
          'support'
        ]
      }
    },
    {
      path: 'currency-rates',
      name: 'CurrencyRatesSetting',
      component: () => import('@/views/setting/page/CurrencyRates.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer'
        ]
      }
    },
    {
      path: 'clear-cache',
      name: 'ClearCache',
      component: () => import('@/views/setting/page/ClearCache.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer'
        ]
      }
    },
    {
      path: 'bc-log',
      name: 'BCLogs',
      component: () => import('@/views/setting/page/BCLogs.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer'
        ]
      }
    },
    {
      path: 'bc-queues',
      name: 'BCQueues',
      component: () => import('@/views/setting/page/BCQueues.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer'
        ]
      }
    },
    {
      path: 'bc-switch',
      name: 'BCSwitch',
      component: () => import('@/views/setting/page/BCSwitch.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer'
        ]
      }
    },
    {
      path: 'release-note',
      name: 'ReleaseNote',
      component: () => import('@/views/setting/page/ReleaseNote.vue'),
      meta: {
        middleware: [
          'auth',
          'developer'
        ]
      }
    },
    {
      path: 'pos-management',
      component: () => import('@/views/posManagement/index.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      },
      children: [
        {
          path: 'list',
          name: 'PosManagement',
          component: () => import('@/views/posManagement/pages/list.vue')
        },
        {
          path: 'create',
          name: 'CreatePos',
          component: () => import('@/views/posManagement/pages/create.vue')
        },
        {
          path: 'edit/:id',
          name: 'EditPos',
          component: () => import('@/views/posManagement/pages/edit.vue')
        },
        {
          path: 'preview/:id',
          name: 'PreviewPosData',
          component: () => import('@/views/posManagement/pages/preview.vue')
        }
      ]
    },
    {
      path: 'dhl-express-setting',
      name: 'DHLExpressSetting',
      component: () => import('@/views/setting/page/DHLExpress.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'accounting_manager',
          'developer',
          'support'
        ]
      }
    }
  ]
}
