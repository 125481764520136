<template>
  <validation-provider
    v-slot="{ errors }"
    ref="validatevalue"
    :name="$attrs.name"
    :rules="rules">
    <v-skeleton-loader
      :loading="loading"
      :height="65"
      type="text"
      class="input-skeleton-loader">
      <p
        v-if="outFieldLabel"
        class="mb-1">
        {{ outFieldLabel }}
        <span
          v-if="isRequiredField"
          class="ml-1 red--text">*</span>
      </p>
      <v-text-field
        v-if="number"
        v-model.number="innerValue"
        :error-messages="errors"
        v-bind="$attrs"
        class="text-field-number"
        :type="type"
        step="any"
        v-on="$listeners" />
      <v-text-field
        v-else
        v-model="innerValue"
        :error-messages="errors"
        :maxlength="maxlength"
        v-bind="$attrs"
        v-on="$listeners" />
    </v-skeleton-loader>
  </validation-provider>
</template>

<script>
export default {
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    number: {
      type: Boolean,
      default: false
    },
    value: {
      type: null,
      default: null
    },
    validateValue: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: null
    },
    outFieldLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      innerValue: null
    }
  },
  computed: {
    isRequiredField () {
        return this.rules?.required ?? false
    }
  },
  watch: {
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    value (newVal) {
      this.innerValue = newVal
      this.setValidateValue()
    }
  },
  created () {
    if (this.value) {
      if (this.number) {
        this.innerValue = Number(this.value)
      } else {
        this.innerValue = this.value
      }
    }
  },
  methods: {
    setValidateValue () {
      if (this.validateValue) {
        setTimeout(async () => {
          await this.$refs.validatevalue.validate()
        }, 50)
      }
    }
  }
}
</script>
