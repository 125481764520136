<template>
  <v-app :class="{ 'v-application--is-mobile': isMobile }">
    <component
      :is="layout.components"
      v-if="pageReady && !!layout.label"
      :class="`base-layout layout-${layout.label}`"
      :window-width="$vuetify.breakpoint.width"
      :window-height="$vuetify.breakpoint.height"
      :is-mobile="isMobile" />
    <gw-snackbar
      :value="snackbar.value"
      :message="snackbar.message"
      :type="snackbar.type"
      :can-close="snackbar.canClose"
      :timeout="snackbar.timeout"
      @close="closeSnackbar()" />
    <modal
      :active="modal.value"
      :confirm-text="modal.confirmText"
      :cancel-text="modal.cancelText"
      :message="modal.message"
      :title="modal.title"
      :confirm-only="modal.confirmOnly"
      :confirm-type="modal.confirmType"
      :cancel-type="modal.cancelType"
      @onConfirm="confirmModal()"
      @onCancel="closeModal()" />
    <page-loading />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import snackbar from '@/components/Snackbar'
import modal from '@/components/Modal'
import pageLoading from '@/components/PageLoading'
import Blank from '@/components/layout/Blank.vue'
import Default from '@/components/layout/Default.vue'

export default {
  components: {
    snackbar,
    modal,
    pageLoading
  },
  data () {
    return {
      layoutData: {
        Blank,
        Default
      }
    }
  },
  computed: {
    ...mapGetters({
      snackbar: 'Components/snackbar',
      modal: 'Components/modal',
      darkMode: 'Style/darkMode',
      pageReady: 'Store/pageReady'
    }),
    layout () {
      const layouts = this.$route?.meta?.layout || 'Default'
      return {
        label: layouts,
        components: this.layoutData[layouts]
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  watch: {
    darkMode (val) {
      this.$vuetify.theme.dark = val
    }
  },
  created () {
    this.setIsWebpSupported()
    this.$vuetify.theme.dark = this.darkMode
    this.initStore()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setDarkMode: 'Style/setDarkMode',
      setIsWebpSupported: 'Style/setIsWebpSupported',
      initStore: 'Store/initStore'
    }),
    confirmModal () {
      if (this.modal.onConfirm) {
        this.modal.onConfirm()
      }
      this.setModal({ value: false })
    },
    closeModal () {
      if (this.modal.closeModal) {
        this.modal.closeModal()
      }
      this.setModal({ value: false })
    },
    closeSnackbar () {
      this.setSnackbar({ value: false })
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/css/gw-variables.scss';

:focus {
  outline: unset;
}
html {
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgb(174, 174, 183);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

//scroll
// ::-webkit-scrollbar {
//   width: 10px;
// }

// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

#app {
  font-family: $body-font-family;
  .display-4 {
    font-family: $body-font-family;
  }
  .display-3 {
    font-family: $body-font-family;
  }
  .display-2 {
    font-family: $body-font-family;
  }
  .display-1 {
    font-family: $body-font-family;
  }
  .headline {
    font-family: $body-font-family;
  }
  .title {
    font-family: $body-font-family;
  }
  .subtitle-1 {
    font-family: $body-font-family;
  }
  .subtitle-2 {
    font-family: $body-font-family;
  }
  .body-1 {
    font-family: $body-font-family;
  }
  .body-2 {
    font-family: $body-font-family;
  }
  .caption {
    font-family: $body-font-family;
  }
  .overline {
    font-family: $body-font-family;
  }
  .attribute-tag.color-0,
  .warehouse-color-0 {
    background-color: #c379aa;
    color: white;
  }
  .attribute-tag.color-1,
  .warehouse-color-1 {
    background-color: #78cfec;
  }
  .attribute-tag.color-2,
  .warehouse-color-2 {
    background-color: #44c6d5;
  }
  .attribute-tag.color-3,
  .warehouse-color-3 {
    background-color: #374267;
    color: white;
  }
  .attribute-tag.color-4,
  .warehouse-color-4 {
    background-color: #a5aeda;
  }
  .attribute-tag.color-5,
  .warehouse-color-5 {
    background-color: #ef4f67;
  }
  .attribute-tag.color-6,
  .warehouse-color-6 {
    background-color: #f8976a;
  }
  .attribute-tag.color-7,
  .warehouse-color-7 {
    background-color: #5fc2a2;
  }
  .attribute-tag.color-8,
  .warehouse-color-8 {
    background-color: #28b75d;
    color: white;
  }
  .attribute-tag.color-9,
  .warehouse-color-9 {
    background-color: #8d537a;
    color: white;
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 95%;
  }
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
// html::-webkit-scrollbar {
//   display: none;
// }
header.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
}
.theme--light main.v-main {
  background-color: #f1f1f1;
}
.theme--dark main.v-main {
  background-color: #464646;
}
.v-application--is-mobile .base-layout {
  height: calc(100vh - 110px);
}
.v-application {
  .base-layout {
    max-height: calc(100vh);
    height: calc(100vh);
  }
  main.v-main {
    min-height: 100vh;
  }
}
.v-application--is-ltr div.v-list-item__action:first-child,
.v-application--is-ltr div.v-list-item__icon:first-child {
  margin-right: 10px;
}
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > a.v-list-item {
  padding-left: 35px;
}
.main-card.v-card {
  height: 100%;
  margin-top: 3px;
  overflow-y: auto;
}
.text-field-number input[type=number]::-webkit-inner-spin-button,
.text-field-number input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-pointer {
  cursor: pointer;
}
// header.app-bar-mobile > .v-toolbar__content * {
//   color: #ffffff;
// }
header.app-bar-mobile >.v-toolbar__extension {
  background-color: #ffffff;
}
.skus-table table {
  margin-bottom: 10px;
}
.v-input input {
  font-family: "Arial" !important;
}
#sidebar {
  .v-list-item--link:before {
    background-color: #000000;
    box-shadow: inset -2px -1px 2px 1px #717171;
  }
  .v-list-group__header.v-list-item {
    height: 40px;
  }
  .v-list-item.v-list-item--active:hover::before {
    opacity: 0.24;
  }
  .v-list-group__header.v-list-item:hover::before,
  .v-list-item:hover::before {
    opacity: 0;
  }
}
#product-form {
  max-width: 898px;
  margin: 0 auto;
  padding-right: 62px;
  padding-left: 62px;
  background-color: #ffffff;
  .v-input__control {
    position: relative;
  }
  .v-text-field__details {
    position: absolute;
    width: 100%;
    bottom: -10px;
  }
  .v-input--dense .v-text-field__details {
    bottom: -20px;
  }
}
.disabled-label .v-input:not(.v-input--radio-group) label.v-label {
  display: none;
}
.input-skeleton-loader {
  >.v-skeleton-loader__text {
    height: 50%;
  }
  >.v-skeleton-loader__list-item-three-line {
    width: 100%;
  }
}
.input-skeleton-loader.v-skeleton-loader--is-loading {
  display: flex;
  align-items: center;
}
.capitalize {
  text-transform: capitalize;
}
div.page[size=A4] {
  background: #fff;
  width: 230mm;
  min-height: 297mm;
  display: block;
  margin: 40px auto 0;
  padding: 10mm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,.5);
}
@media screen and (min-width: 768px) {
  .v-main__wrap {
    padding: 10px 20px;
  }
}
.products-list-page {
  .head-search {
    position: sticky;
    top: 50px;
    z-index: 5;
    background-color: #ffffff;
    box-shadow: 1px 2px 5px 0px #c2c2c2;
  }
  .product-attribute-left {
    width: 300px;
    flex: 0 0 300px;
  }
  .product-col-right .row .col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  /* .product-col-left {
    width: 150px;
    flex: 0 0 150px;
  } */
  .edit-product-btn {
    position: absolute;
    width: fit-content;
    top: 20px;
    right: 0;
  }
  .products-row {
    position: relative;
    padding: 10px 0;
    margin: 5px 0;
    background-color: #fff;
  }
  .product-image {
    display: block;
    width: 115px !important;
    padding: 5px;
    margin: 0 auto;
    border: 1px dashed #676767;
    background-color: #fff;
  }
  .product-attribute p {
    margin-bottom: 5px;
  }
  .attribute-tag {
    background-color: #93d7ef;
    color: #ffffff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 20px;
    display: inline-block;
  }
  .loader-product-list .v-skeleton-loader__avatar {
    width: 100px;
    height: 100px;
  }
  .loader-product-list .v-skeleton-loader__list-item-avatar-three-line {
    height: 100%;
  }
  .loader-product-list {
    padding: 5px 0;
  }
}
.col-search {
  position: relative;
  .tags-overlay {
    position: absolute;
    width: 120%;
    max-width: 300px;
    bottom: 0;
    right: 8px;
    transform: translateY(100%);
    background-color: #666666;
    z-index: 2;
    .tags-overlay-head,
    .tags-operation {
      display: flex;
      align-items: center;
    }
    .tags-overlay-head {
      background-color: #24282D;
      .text-caption {
        font-size: 11px !important;
        color: #ffffff;
      }
    }
    .tags-overlay-content {
      height: 250px;
      overflow-y: auto;
      background-color: #30373D;
    }
    .tags-overlay-content.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tag-chip {
      cursor: pointer;
    }
  }
  .tag-chip button.mdi-close-circle {
    font-size: 12px !important;
  }
  .tags-overlay::before {
    position: absolute;
    content: "";
    z-index: -1;
    background: #24282D;
    width: 20px;
    height: 20px;
    top: -5px;
    right: 8px;
    transform: rotateY(1deg) rotateZ(-45deg) scaleX(1) scaleY(1) scaleZ(1);
  }
  .tags-overlay-content::-webkit-scrollbar {
    width: 5px;
  }
  .tags-overlay-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .tags-overlay-content::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .v-input.search-input.v-text-field > .v-input__control {
    min-height: unset;
    .v-input__slot {
      max-width: 300px;
      overflow-x: auto;
      overflow-y: hidden;
      cursor: unset;
    }
    input {
      font-size: 12px;
      min-width: 150px;
    }
  }
  .v-input.search-input.v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border: 1px solid #d5d5d5;
    cursor: pointer;
  }
  // .v-input.search-input.v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }
  .v-input.search-input.v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .v-input.search-input.v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar-thumb {
    background: #888;
  }
  .v-input.search-input.v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.v-input.select-warehouse {
  width: 200px;
  font-size: 12px;
  input {
    display: none;
  }
}
.v-input.v-textarea.v-input--is-disabled fieldset {
  background-color: #0000001a;
}
.count-tab circle.v-progress-circular__overlay {
  stroke-width: 5px;
}
@media screen and (min-width: 768px) {
  .products-list-page {
    .product-detail {
      position: relative;
    }
    .edit-product-btn {
      top: auto;
    }
  }
}
@media print {
  #app {
    main.v-main {
      background-color: #ffffff !important;
    }
    .print-dp-none {
      display: none !important;
    }
    .buttun-remove-product,
    .buttun-remove-sku {
      display: none;
    }
    div[size=A4] {
      width: 100%;
      margin: 0 !important;
      padding: 0;
      box-shadow: unset !important;
      max-width: 100%;
      min-height: auto;
      // .row.page-break {
      //   page-break-before: avoid;
      //   display: inline-block;
      //   width: 100%;
      //   > * {
      //     float: left;
      //   }
      // }
      // .row.note-text.page-break > * {
      //   float: right;
      // }
      .v-input.note-count-input {
        .v-input__slot {
          background-color: transparent;
          fieldset {
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: 1px;
            border-left-width: 0;
            border-radius: 0;
          }
          input {
            display: none;
          }
        }
      }
    }
}
}

</style>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.limit-text {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
