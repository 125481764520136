<template>
  <v-navigation-drawer
    id="sidebar"
    :value="value"
    :mini-variant="!isMobile ? mini : false"
    :mini-variant-width="80"
    :absolute="isMobile"
    :temporary="isMobile && value"
    :permanent="!isMobile"
    app
    dark
    @input="update($event)">
    <template v-slot:prepend>
      <v-list-item class="head-branding">
        <v-list-item-avatar
          v-if="!mini || isMobile"
          id="branding"
          min-width="150"
          max-width="150">
          <router-link
            :to="{ name: 'Home' }"
            class="text-uppercase">
            {{ store.name }}
          </router-link>
        </v-list-item-avatar>
        <v-btn
          v-if="isMobile"
          class="btn-collect-menu"
          icon
          @click.stop="update(false)">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-list-item>
    </template>

    <div
      v-if="isShowCreateOrder"
      class="pa-5">
      <v-btn
        v-if="!mini"
        block
        color="primary"
        :to="{ name: 'NewOrder' }">
        Create Order
      </v-btn>
      <v-btn
        v-else
        block
        color="primary"
        :to="{ name: 'NewOrder' }">
        <v-icon>
          mdi-clipboard-plus
        </v-icon>
      </v-btn>
    </div>

    <v-list
      dense
      nav
      expand>
      <v-list-item-group
        v-for="(item, index) in menus"
        :key="`menus-${index}`"
        no-action
        mandatory>
        <v-list-group
          v-if="item.subMenus"
          :prepend-icon="mini ? item.icon : null"
          no-action
          :value="false"
          :ripple="false"
          color="#ffffffb3">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                <v-subheader>{{ $t(`menu.sidebar.${item.title}`) }}</v-subheader>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-divider class="pb-2" />
          <list-menu
            v-for="(subMenu, i) in item.subMenus"
            :key="`${item.title}-${subMenu.title}-${i}`"
            :title="subMenu.title"
            :to="subMenu.to"
            :icon="subMenu.icon"
            path="menu.sidebar"
            icon-prepend />
        </v-list-group>
        <list-menu
          v-else
          :title="item.title"
          :to="item.to"
          :icon="item.icon"
          path="menu.sidebar"
          icon-prepend />
      </v-list-item-group>
    </v-list>

    <template
      v-if="isShowSetting"
      v-slot:append>
      <v-divider />
      <router-link
        class="d-flex justify-center pa-2 setting-menu"
        :to="{ name: 'Setting' }">
        <v-icon small>
          mdi-cog
        </v-icon>
        <p class="mb-0 ml-1">
          {{ $t('menu.sidebar.Setting') }}
        </p>
      </router-link>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { getMenuByRole, getRole } from '@/assets/js/Authentication'
import listMenu from '@/components/menu/ListMenu.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    listMenu
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    brandName: {
      type: String,
      required: false,
      default: ''
    },
    mini: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      store: 'Store/store'
    }),
    iconTheme () {
      return this.$vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-moon-waning-crescent'
    },
    menus () {
      return getMenuByRole(this.items, this.$router)
    },
    isShowSetting () {
      const role = getRole()
      const settingRole = [
        'auth',
        'management',
        'area_manager',
        'marketing',
        'developer',
        'support'
      ]
      return settingRole.some((r) => r === role)
    },
    isShowCreateOrder () {
      const role = getRole()
      const createOrderRole = [
        'auth',
        'management',
        'online_admin',
        'marketing',
        'developer'
      ]
      return createOrderRole.some((r) => r === role)
    }
  },
  methods: {
    ...mapActions({
      toggleOrderModal: 'Order/toggleOrderModal'
    }),
    update (value) {
      this.$emit('input', value)
    },
    openOrderModal () {
      this.toggleOrderModal({
        active: true
      })
    }
  }
}
</script>

<style scoped>
div.head-branding {
  height: 64px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
div.head-branding a {
  color: #ffffff;
  text-decoration: unset;
}
div#branding {
  border-radius: 0;
  font-weight: bold;
  margin: 0 auto;
}
button.v-btn.btn-collect-menu {
  position: absolute;
  right: 10px;
}
a.setting-menu {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
}
</style>
