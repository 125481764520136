import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PromotionProvider extends HttpRequest {
  validatePromotion (payload) {
    this.setHeader(getAuthToken())
    return this.post('/promotion-codes/validate', payload)
  }

  superValidatePromotion (payload) {
    this.setHeader(getAuthToken())
    return this.post('/promotion-codes/super-validate', payload)
  }

  getAllPromotions (querys) {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-codes?${querys}`)
  }

  getMemberCampaignUse (querys) {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-codes/${querys}/get-member-campaign-used`)
  }

  getAllPromotionsByPaginate (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/promotion-codes', query)
  }

  getPromotion (param) {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-codes/${param}`)
  }

  getPromotionByCode (param) {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-codes/${param}/code`)
  }

  removePromotion (param) {
    this.setHeader(getAuthToken())
    return this.delete(`/promotion-codes?id=${param}`)
  }

  getPromotionByMember (param, querys) {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-codes/member/${param}?${querys}`)
  }

  getPromotionByMemberPaginate (param, query) {
    this.setHeader(getAuthToken())
    return this.getByPagination(`/promotion-codes/member/${param}`, query)
  }

  createPromotion (payload) {
    this.setHeader(getAuthToken())
    return this.post('/promotion-codes', payload)
  }

  createMultiPromotion (payload) {
    this.setHeader(getAuthToken())
    return this.post('/promotion-codes/many', payload)
  }

  updatePromotion (param, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/promotion-codes/${param}`, payload)
  }

  getOrderHistory (id, params) {
    this.setHeader(getAuthToken())
    return this.get(`/members/promotion-codes/${id}/order-history?${params}`)
  }

  getTags () {
    this.setHeader(getAuthToken())
    return this.get('/product/tags')
  }

  getCategories () {
    this.setHeader(getAuthToken())
    return this.get('/product/categories')
  }

  getAllAttibutes () {
    this.setHeader(getAuthToken())
    return this.get('/products/attributes')
  }

  checkIdTags (payload) {
    this.setHeader(getAuthToken())
    return this.post('/product/tags/get-ids', payload)
  }

  checkIdTels (payload) {
    this.setHeader(getAuthToken())
    return this.post('/members/get-ids', payload)
  }

  checkIdCategories (payload) {
    this.setHeader(getAuthToken())
    return this.post('/product/categories/get-ids', payload)
  }

  getRandomCode () {
    this.setHeader(getAuthToken())
    return this.get('/promotion-codes/random')
  }

  sentSms (payload) {
    this.setHeader(getAuthToken())
    return this.post('/sms/multi', payload)
  }

  getMixAndMacthPricing (options) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/step-pricing', options)
  }

  getMixAndMacthPricingObject () {
    this.setHeader(getAuthToken())
    return this.get('/step-pricing/object')
  }

  createMixAndMacthPricing (payload) {
    this.setHeader(getAuthToken())
    return this.post('/step-pricing', payload)
  }

  updateMixAndMacthPricing (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/step-pricing/${id}`, payload)
  }

  deleteMixAndMacthPricing (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/step-pricing?id=${id}`)
  }

  getPromotionInfoById (param) {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-codes/${param}/info`)
  }
}

export default PromotionProvider
